<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        When learning how to cook pasta, you may have been told to add salt to the water to increase
        the boiling point, allowing the water to get hotter and cook the noodles faster. Let's test
        that concept here. Assume that you have
        <number-value :value="volume" unit="\text{g}" />
        of pure water to which you add
        <number-value :value="massSalt" unit="\text{g}" />
        of sodium chloride,
        <chemical-latex content="NaCl" />
        (a non-volatile solute). Answer each of the questions below.
      </p>

      <p class="mb-3">
        a) What is the boiling point elevation constant,
        <stemble-latex content="$\text{K}_\text{b},$" />
        for water?
      </p>

      <calculation-input
        v-model="inputs.Kb"
        class="mb-5"
        prepend-text="$\text{K}_\text{b}:$"
        append-text="$^\circ\text{C}/\text{m}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">b) What is the molality of the NaCl solution?</p>

      <calculation-input
        v-model="inputs.molality"
        class="mb-5"
        prepend-text="$\text{Molality}:$"
        append-text="$m$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) What is the change in boiling point,
        <stemble-latex content="$\Delta\text{T}_\text{b},$" />
        of this solution? Assume that the van't Hoff factor for the solute is the same as it would
        be for a 0.050 molal solution.
      </p>

      <calculation-input
        v-model="inputs.deltaT"
        class="mb-5"
        prepend-text="$\Delta\text{T}_\text{b}:$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        <b>Note:</b> Regardless of the answer to part c), please don't stop adding salt to your
        water if you currently do. Your taste buds will not forgive you.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question415',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Kb: null,
        molality: null,
        deltaT: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    massSalt() {
      return this.taskState.getValueBySymbol('massSalt').content;
    },
  },
};
</script>
